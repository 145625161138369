<template>
  <section class="banner-box">
    <el-carousel height="800px">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <img class="banner-img" :src="item.img" alt="">
      </el-carousel-item>
    </el-carousel>
  </section>
  <section class="catalogue usually-box">
    <div class="left-box">
      <div class="up-to-date">
        <h3>最新案例</h3>
        <p class="over-text1" v-for="(item, index) in uptoDateCase" :key="index" @click="reload(item)">
          {{item.title}}
        </p>
      </div>
      <div class="rem-box">
        <h3>相关产品</h3>
        <ul>
          <li v-for="(item, index) in remList" :key="index" @click="toPage('/product/engine')">
            <img class="product-img" :src="item.img" alt="">
          </li>
        </ul>
      </div>
    </div>
    <div class="details-box">
      <div class="back" @click="toBack()"><img src="@/assets/case/left-jt.png" alt=""> 返回案例列表</div>
      <h3 class="title">{{projectDetails.title}}</h3>
      <p class="descript">
        {{projectDetails.describe}}
      </p>
      <div class="case-content" v-html="projectDetails.content">

      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, reactive, computed, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { signGetList, caseGetList } from '@/api/case'
const router = useRouter()
function toBack() {
  router.back()
}
function toPage(url) {
  router.push(url)
}
const bannerList = reactive([
  {
    img: require("@/assets/case/banner.png"),
  }
])

function reload(data) {
  sessionStorage.setItem('case', JSON.stringify(data))
  document.documentElement.scrollTop = 0
  const item = JSON.parse(sessionStorage.getItem('case'))
  bannerList[0] = {
    img: item.backgroundImg || require("@/assets/case/banner.png"),
  }
  projectDetails.value = item
}

const uptoDateCase = ref([])

const remList = ref([
  {
    name: "海马链",
    img: require("@/assets/case/haimalogo.png"),
  }
])

const projectDetails = ref({})

onBeforeMount(() => {
  const item = JSON.parse(sessionStorage.getItem('case'))
  bannerList[0] = {
    img: item.backgroundImg || require("@/assets/case/banner.png"),
  }
  projectDetails.value = item
})

caseGetList({
}).then(res => {
  uptoDateCase.value = res.data.data.slice(0, 5)
})
</script>

<style lang='scss' scoped>
$back-color: #fff;
.banner-img {
  width: 100%;
}
.catalogue {
  display: flex;
  margin: 40px 0;
}
.up-to-date,
.rem-box {
  overflow: hidden;
  box-sizing: border-box;
  padding: 30px 20px;
  width: 280px;
  height: 290px;
  background-color: $back-color;
  > h3 {
    margin-bottom: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #0766e1;
    font-size: 20px;
    color: #0766e1;
  }
}
.up-to-date {
  > p {
    padding-right: 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 2.2em;
    background: url(../../assets/case/right-jt.png) right center no-repeat;
    background-size: 15px 15px;
    color: #808080;
    cursor: pointer;
    &:hover {
      color: $hoverColor;
      background: #e3e3e3;
    }
  }
}
.rem-box {
  margin: 20px 0;
  .product-img {
    width: 100%;
  }
}
.left-box {
  flex-shrink: 0;
  margin-right: 20px;
}

.details-box {
  flex: 1;
  padding: 30px 20px;
  background-color: $back-color;
  .back {
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 14px;
    color: #0766e1;
    cursor: pointer;
    > img {
      position: relative;
      top: 1px;
      margin-right: 10px;
      width: 14px;
      height: 14px;
    }
  }
  .title {
    margin: 45px 0 50px;
    font-size: 24px;
    color: #202020;
  }
  .descript {
    font-size: 14px;
    line-height: 1.8em;
    color: #808080;
  }
  .case-content {
    margin: 30px 0;
  }
}
</style>